<template>
  <!-- resp login Modal -->
    <div
      class="login-modal modal fade"
      id="loginModal"
      tabindex="-1"
      aria-labelledby="loginModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :style="{ transform: !mediaQueries.mobileSize ? 'none' : 'inherit' }"
      >
        <div
          :style="{ height: !mediaQueries.mobileSize ? modalHeight : '0' }"
          id="modal-content"
          class="modal-content"
        >
          <div class="modal-header py-4 mx-3">
            <button
              v-if="textModalHeader == 'ورود به وبسایت'"
              id="btnCloseLoginModal"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true" style="font-size: 30px">&times;</span>
            </button>
            <button
              v-if="textModalHeader == 'فراموشی رمز عبور'"
              id="btnCloseLoginModal"
              type="button"
              @click="backToLogin()"
              class="backBtn"
            >
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
<!-- 
            <h5
              class="modal-title fontsize16 font-weight-bold text-color-444"
              id="loginModalLabel"
            >
              {{ textModalHeader }}
            </h5> -->
      
              <img src="@/assets/images/logo.png" alt="logo-unique-shoes" style="width: 80px;">
           
          </div>
          <LoginRegister v-model="modalHeight" :textModalHeader="textModalHeader" @changeTextModal="changeText" ref="LoginRegister" :showFullForm="false" />
                  </div>
      </div>
    </div>
</template>

<script>
import LoginRegister from "@/parts/Front/components/loginRegister/LoginRegister.vue"
import closeModalMixin from "@/libraries/mixins/closeModalMixin";
export default {
components:{LoginRegister},
  inject:['mediaQueries'],
  name: "RespLoginModal",
  mixins: [closeModalMixin],
  data() {
    return {
      modals: ['loginModal'],
      textModalHeader: "ورود به وبسایت",
      modalHeight:"0",
      changeText:"ورود به وبسایت",
    };
  },
  methods: {
    closeModal() {
      this.$refs.LoginRegister.closeModal();
      // برگرداندن به وضعیت اولیه با بستن مودال
    },

  },
  watch:{
    changeText(val){
      this.textModalHeader=val;
    }
  }

};
</script>

<style scoped>

.modal-header{
  justify-content: flex-start !important;
  position: relative;
}
.modal-header img{
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%,-50%);
}
@media screen and (max-width:468px) {
  .modal-header{
    justify-content: flex-start !important;
    
  }
}
.modal-header .close {
  margin: -1rem -1rem -1rem 10px !important;
}
/* افزودن استایل به کدهای اضافه شده به قالب */
#mobile {
  width: 95%;
  background: white;
  height: 30px;
  border-radius: 0;
}
#signupCheckbox {
  display: flex;
  justify-content: space-around;
}
#signupCheckbox div input {
  position: relative;
  top: 4px;
  left: 5px;
}
#codeInput {
  direction: ltr;
}
#otherUserOption {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin: auto;
  margin-top: 10px;
}
#otherUserOption p {
  cursor: pointer;
  transition: "text-shadow" 1s;
}
#otherUserOption p:hover {
  text-shadow: -1px 1px 7px blue;
}
.loginInputs {
  width: 95%;
  padding-right: 5px;
  line-height: 36px;
}
#closeModal2 {
  background-color: silver;
}
#continueSignUpSection {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
#continueSignUpSection input {
  border-radius: 5px;
  margin-left: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#loginHeader {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
#loginWithTokenLink {
  cursor: pointer;
  transition: "text-shadow" 1s;
}
#loginWithTokenLink:hover {
  text-shadow: -1px 1px 7px blue;
}
/* اصلاح قالب
 */
/* درست کردن قالب ورود کد */
.login-modal .modal-body input[type="tel"], .login-modal .modal-body input[type="number"] {
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 10px;
  width: 60px;
  height: 60px;
}
@media screen and (max-width: 390px) {
  .login-modal .modal-body input[type="tel"], .login-modal .modal-body input[type="number"] {
    width: 50px;
    height: 50px;
  }
}
/* برداشتن دکمه های بالا پایین برای ورود عدد */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#showSignUpResult {
  text-align: center;
  display: flex;
  flex-direction: column;
}
#showSignUpResult i {
  color: green;
  font-size: 50px;
}
.login-modal .modal-content {
  height: max-content !important;
  border-radius: 20px !important;
}
#mobileInput {
  background-color: var(--color-input) !important;
    padding: 10px!important;
    border-radius: 10px !important;
}
#modal-content {
  transition: all 0.3s;
  height: 300px;
}

.modal-content {
  min-height: 340px;
}
/* نمایش تمام صفحه مودال در حالت موبایلی
 */
@media (max-width: 768px) {
  #modal-content {
    height: 100vh !important;
    border-radius: 0 !important;
    width: 100% !important;
    position: fixed;
    top: 0;
  }
  .modal-dialog {
    margin: 0;
  }
}
#hrInput {
  margin-top: 0.5rem;
}
#modal-content {
  transition: all 1s;
}
#loginErrorBox {
  margin-top: 5px;
}
#backBtn {
  background: transparent;
  font-size: larger;
}
.numberWar {
  margin: 2px;
  padding: 10px;
  text-align: center;
}
#btnCloseLoginModal{
  background-color:transparent
}
</style>
