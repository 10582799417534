<template>
  <section class="main_container categoreis-home">
    <!-- <b-skeleton-img v-if="!categories"></b-skeleton-img> -->

    <div
      v-if="categories && categories.length"
      id="categoreis-slider"
      class="categoreis-slider position-relative"
    >
      <div class="swiper-container ">
        <div class="swiper-wrapper">
          <div class="swiper-slide " v-for="(category, index) in categories">
            <CardCategore :data="category" :key="category.id" />
          </div>
        </div>
      </div>
      <!-- <div class=" swiper-button-next text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="18"
          fill="currentColor"
          class="bi bi-chevron-compact-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
            fill="#999"
          />
        </svg>
      </div>
      <div class=" swiper-button-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="18"
          fill="currentColor"
          class="bi bi-chevron-compact-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
            fill="#999"
          />
        </svg>
      </div> -->
    </div>
  </section>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min.js";

// import findUrl from "@/libraries/links/LinkResolver";
import CardCategore from "@/parts/Front/components/HomeCategoreis/card.vue";

// import DynamicLinker from "../../components/shared/DynamicLinker.vue";
// import SearchButton from "../header/SearchButton";
import { BSkeletonImg } from "bootstrap-vue";

export default {
  name: "HomeCategoreis",
  inject: ["mediaQueries"],
  components: {
    // SearchButton,

    // DynamicLinker,
    CardCategore,
    BSkeletonImg,
  },

  inject: ["mediaQueries"],

  methods: {
    // initHeaderSwiper(kill = false) {
    //   this.initSwiper("categoreis", kill, () => {
    //     new Swiper(".categoreis .swiper-container", {
    //       spaceBetween: 0,
    //       //   centeredSlides: true,
    //       speed: 1000,
    //       loop: true,
    //       autoplay: {
    //         delay: 4500,
    //         disableOnInteraction: false,
    //       },
    //       breakpoints: {
    //           250: {
    //             slidesPerView: 2,
    //             spaceBetween: 1,
    //           },
    //           500: {
    //             slidesPerView: 2,
    //             spaceBetween: 15,
    //           },
    //           768: {
    //             slidesPerView: 3,
    //             spaceBetween: 20,
    //           },
    //           992: {
    //             slidesPerView: 4,
    //             spaceBetween: 20,
    //           },
    //           // 1200: {
    //           // slidesPerView: 3,
    //           // spaceBetween: 0,
    //           // }
    //         },
    //       navigation: {
    //         nextEl: ".categoreis .swiper-button-next",
    //         prevEl: ".categoreis .swiper-button-prev",
    //       },
    //     });
    //   });

    // },
    initCategoreis(kill = false) {
      this.initSwiper("categoreis-home", kill, () => {
        new Swiper("#categoreis-slider .swiper-container", {
          spaceBetween: 30,
          // centeredSlides: true,
          loop: false,

          navigation: {
            nextEl: "#categoreis-slider .swiper-button-next",
            prevEl: "#categoreis-slider .swiper-button-prev",
          },
          // breakpoints: {
          //   250: {
          //     slidesPerView: 3.8,
          //     spaceBetween: 40,
          //   },
          //   390: {
          //     slidesPerView: 3.5,
          //     spaceBetween: 0,
          //   },
          //   460: {
          //     slidesPerView: 4,
          //     spaceBetween: 10,
          //   },
          //   500: {
          //     slidesPerView: 5,
          //     spaceBetween: 10,
          //   },
          //   768: {
          //     slidesPerView: 5,
          //     spaceBetween: 40,
          //   },
          //   992: {
          //     slidesPerView: 8,

          //     spaceBetween: 50,
          //   },
          //   1300: {
          //     slidesPerView: 10,
          //     spaceBetween: 50,
          //   },
          //   1400: {
          //     slidesPerView: 10,
          //     spaceBetween: 50,
          //   },
          //   1600: {
          //     slidesPerView: 11,
          //     spaceBetween: 50,
          //   },
          // },
          breakpoints: {
            300: {
              slidesPerView: 2.3,
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1100: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          },
        });
      });
    },
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
  },
  mounted() {
    this.initCategoreis();
  },
  watch: {
    categories() {
      this.initCategoreis();
    },
    // specialCategories() {
    //   this.initCategoreis();
    // },
  },
  computed: {
    showCount() {
      if (this.mediaQueries.xxxLarge) {
        return 10;
      }
      if (this.mediaQueries.xxLarge) {
        return 9;
      }
      // if (this.mediaQueries.xLarge) {
      //   return 7;
      // }
      // if (this.mediaQueries.xxSmall) {
      //   return 3
      // }
      // if (this.mediaQueries.xSmall) {
      //   return 4
      // }
      // if (this.mediaQueries.medium) {
      //   return 6
      // }
      return 9;
    },
    categories() {
      // return this.$store.getters["front/getCategories"] ;
      console.log(
        this.$store.getters["front/getHomeData"],
        "mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm"
      );
      if (this.$store.getters["front/getHomeData"] == null) {
        return null;
      } else {
        return this.getEnough(
          this.$store.getters["front/getHomeData"].special_categories
        );
      }
    },
    // specialCategories(){
    //   console.log(this.$store.getters["front/getHomeData"],"mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm");
    //   if(this.$store.getters["front/getHomeData"]==null){
    //     return null;
    //   }else{
    //     return this.getEnough(this.$store.getters["front/getHomeData"].special_categories);
    //   }
    // }
  },
 
  
};
</script>

<style scoped>
.categoreis-home {
  margin-top: 70px;
}
.swiper-button-prev,
.swiper-button-next {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  position: absolute;
  border: 1px solid #e9e9e9;
  transition: all 0.3s;
}
.swiper-button-next {
  right: -20px;
}
.swiper-button-prev {
  left: -20px;
}
.categoreis-home .swiper-button-prev:hover,
.categoreis-home .swiper-button-next:hover {
  background-color: #000;
}
.categoreis-home .swiper-button-prev .bi path {
  stroke-width: 3px;
}
.categoreis-home .swiper-button-prev:hover .bi path,
.categoreis-home .swiper-button-next:hover .bi path {
  fill: #fff;
}
@media screen and (max-width: 639px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .categoreis-home{
    margin-top: 0px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
<style>
.categoreis-home .swiper-wrapper {
  padding: 8px 0;
}
.categoreis-home .swiper-button-prev:after,
.categoreis-home .swiper-container-rtl .swiper-button-next:after,
.categoreis-home .swiper-button-next:after,
.categoreis-home .swiper-container-rtl .swiper-button-prev:after {
  display: none;
}
@media screen and (max-width: 639px) {
  .categoreis-home .swiper-wrapper {
    padding: 0px 0;
  }
}
</style>
