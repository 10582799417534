<template>
  <!-- <div class="box-shaddow20">
    <header
      class="container d-flex align-items-center  justify-content-between px-lg-1 px-3 py-2 py-md-0"
    >
      <div
        class="d-lg-flex d-none align-items-center justify-content-start flex-grow-1"
      >
        <MegaMeun />
      </div>

      <div
        class="d-lg-flex d-none align-items-center justify-content-center flex-grow-1 py-4 py-lg-2 logo-header"
      >
        <Logo />
      </div>

      <div class="d-flex d-lg-none align-items-center justify-content-start">
        <button
          type="button"
          id="all-categories"
          class="all-categories bg-transparent resp-menu-btn d-lg-none"
          @click="$emit('openRespMenu')"
        >
          <MenuBar />
        </button>
        <Logo />
      </div>
      <div
        class="d-flex align-items-center justify-content-end flex-grow-1 header-icons"
      >
        <button
          class="bg-transparent"
          type="button"
          @click="showCloseSearchBox()"
        >
          <searchSvg />
        </button>

        <button class="bg-transparent" type="button" @click="cartListsOpen()">
          <CartButton />
        </button>
        <LoginUser :atlasTheme="true" />
      </div>
    </header>
  </div> -->
  <section >
    <div class="main_container top_header">
      <button
      type="button"
      id="all-categories"
      class="all-categories bg-transparent resp-menu-btn d-flex d-lg-none"
      @click="$emit('openRespMenu')"
    >
      <MenuBar />
    </button>
      <Logo />
      <searchHeader class="searchHeader"/>
      <div
      class="d-flex align-items-center justify-content-end  header-icons"
    >
      <!-- <button
        class="bg-transparent"
        type="button"
        @click="showCloseSearchBox()"
      >
        <searchSvg />
      </button> -->

      <button class="bg-transparent" type="button" @click="cartListsOpen()">
        <CartButton />
      </button>
      <LoginUser :atlasTheme="true" />
    </div>
    </div>
    <div class="bottom_header" id="bottom_header">
      <div class="main_container">
        <MegaMeun />

      </div>
    </div>
  </section>
</template>

<script>
import searchHeader from "@/parts/Front/components/searchHeader";

import searchSvg from "./svg/Search.vue";
import CartButton from "./cart/CartButton.vue";
import { cartListsOpen, openSearch } from "@/libraries/functions";
import Logo from "@/parts/Front/components/AtlasLogo";
import MegaMeun from "../components/MegaMenu.vue";
import LoginUser from "@/parts/Front/components/LoginUser";
import UserSvg from "./svg/User.vue";
import MenuBar from "./svg/MenuBar.vue";
export default {
  components: {
    searchHeader,
    searchSvg,
    Logo,
    MegaMeun,
    CartButton,
    LoginUser,
    UserSvg,
    MenuBar,
  },
  computed: {
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  },
  mounted(){
    window.addEventListener("scroll",this.scroolHeader)
  },
  methods: {
    cartListsOpen() {
      cartListsOpen();
      this.$router.push(this.$route.fullPath + "#cart-modal");
    },
    showCloseSearchBox() {
      openSearch();
    },
    scroolHeader(){
      if(scrollY>50){
        document.getElementById("bottom_header").classList.add("scrool-header")
      }else{
        document.getElementById("bottom_header").classList.remove("scrool-header")

      }
    }
  },
  beforeDestroy(){
    window.removeEventListener("scroll",this.scroolHeader)

  }
};
</script>

<style scoped>
/*new header*/
.top_header{
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.bottom_header{
  padding-top: 10px;
}

.header-icons button {
  padding: 0 12px 0 0;
}
.logo-header{
  transform: translateX(50%);
  position: absolute;
  right: 50%;
}
.scrool-header{
  position: fixed;
    top: -100px;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    padding-bottom:16px !important;

    animation: moveScrool 1s linear forwards;
}
@keyframes moveScrool {
  0%{
    top: -100px;
  }
  100%{
    top: 0;
  }
}
@media screen and (max-width:990px){
  .searchHeader{
    display: none;
  }
  .bottom_header{
    display: none;
  }
  .header-icons .login-register{
    padding: 0 !important;
  }
}
</style>
