<template>
  <header class="main-header box-shaddow20" id="main-header">
    <!-- <TopHeader @openRespMenu="openRespMenu"></TopHeader> -->
    <NewHeader @openRespMenu="openRespMenu"/>
    <!-- <MainSearch></MainSearch> -->
    <!-- resp login Modal -->
    <RespLoginModal></RespLoginModal>
    <CartLists></CartLists>
    <RespMenu ref="respMenu"></RespMenu>


  </header>
</template>

<script>
import TopHeader from "@/parts/Front/components/TopHeader";
import MainSearch from "@/parts/Front/components/MainSearch";
import RespLoginModal from "@/parts/Front/components/RespLoginModal";
import RespMenu from "@/parts/Front/components/RespMenu";
import CartLists from "@/parts/Front/components/CartLists";
import NewHeader from '../NewHeader.vue'
export default {
  name: "HeaderSecond",
  components:{
    TopHeader,
    MainSearch,
    RespLoginModal,
    RespMenu,
    CartLists,
    NewHeader
  },
  methods: {
    openRespMenu() {
      this.$refs.respMenu.allCategoriesToggle()
    },
    scroolHeader(){
      if(scrollY>50){
        document.getElementById("main-header").classList.add("scrool-header")
      }else{
        document.getElementById("main-header").classList.remove("scrool-header")

      }
    }
  },
  mounted(){
    window.addEventListener("resize",()=>{
      if(innerWidth<990){

        window.addEventListener("scroll",this.scroolHeader)
      }else{

        window.removeEventListener("scroll",this.scroolHeader)
      }
    })
  },
  beforeDestroy(){
    window.removeEventListener("scroll",this.scroolHeader)

  }
}
</script>

<style scoped>
.main-header{
 /* box-shadow: 1px 1px 10px gray;*/
 background-color: #fff;
padding: 20px 0 12px;
}
.scrool-header{
  position: fixed;
    top: -100px;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    animation: moveScrool 1s linear forwards;
}
@keyframes moveScrool {
  0%{
    top: -100px;
  }
  100%{
    top: 0;
  }
}
@media screen and (max-width:600px){
  .main-header{
   padding: 0px 0 0px;
   }
}
</style>
