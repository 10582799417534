<template>
  <article class="  card-categorei category__box" v-if="data">
    <!-- <router-link to="/">
            <figure class="w-100 h-100 m-0 overflow-hidden">
                <img class="w-100 h-100" src="../../../../assets/images/5.webp" :alt="data.title">
               
            </figure>
            <h4 class="card-categorei-title">
                {{ data.title }}

            </h4>
        </router-link> -->
    <!-- <DynamicLinker class=" category__box__link" :item="data" :class="{'them2':them==2}">

                <figure class="figure-image" v-if="data.icon&&data.icon.url">
                    
                    <img class="img" :src="data.icon.url">
                </figure>
                <div class="info">
                    <p>
                        {{ data.title }}
                    </p>
                </div>
                
                
            </DynamicLinker> -->

    <router-link
      :class="{ them2: them == 2 }"
      class="category__box__link"
      
      :to="`/products/category/${data.id}/${data.slug}`"
    >
      <figure class="figure-image" v-if="data.icon">
        <img class="img" :src="data.icon.url" :alt="data.title" />
      </figure>
      <div class="info">
        <p>
          {{ data.title }}
        </p>
      </div>
    </router-link>

    <!-- :to="{
      name: 'product.category',
      params: { category: data.id, slug: data.slug },
    }" -->
  </article>
  <!-- <DynamicLinker class="top-slider-img" :item="categorie">
              <img
                :src="categorie.icon.url"
                class="w-100 h-100"
                :alt="categorie.title"
              />
            </DynamicLinker> -->
</template>

<script>
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";

export default {
  name: "HomeCategoreis_card",
  components: { DynamicLinker },
  props: {
    data: Object,
    them: {
      type: Number,
      default: 1,
    },
  },
};
</script>
<style scoped>
.card-categorei {
  width: 100px;
  height: fit-content;
}
.category__box__link{
  color: #000;
}
/*

.card-categorei-title{
    color: #000;
    transition: all .5s;
    margin-top: 8px;
    font-weight: 600;
    font-size: 14px;
    width: 100px;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;

}
.card-categorei figure{
    border-radius: 50%;
    width: 100%;
    overflow: hidden;
    transition: all .3s;
 
}
.card-categorei img{
    transition: all .5s;
    border-radius: 50%;
    width: 100%;
    object-fit: cover;

}

.card-categorei:hover.card-categorei figure{
   outline: 2px solid var(--color-themeRed);
     
    outline-offset: 3px;
  
}

@media screen and (max-width:639px) {
    .card-categorei-title{
        padding: 2px 20px;
        font-size: 14px;
    }
    .card-categorei{
        width:90px;
    
       
      
    }
}
@media screen and (max-width:360px) {
    .card-categorei-title{
      
        padding: 4px 20px;
      
        font-size: 14px;
   
      
    
    }
  
}*/

.category__box {
  display: inline-block;
  width: 100%;
  transition: all 0.5s 0.2s;

  overflow: hidden;
}
.figure-image {
  margin: 0;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
}
.img {
  width: 100%;
  transition: all 0.5s 0.3s;
  border-radius: 14px;
}

.them2 .img,
.them2 {
  border-radius: unset !important;
}
.them2 .img {
}
@media (max-width: 480px) {
  .them2 .img {
    height: 150px;
  }
}
.them2 .figure-image::before {
  content: "";
  left: -70%;
  background: hsla(0, 0%, 100%, 0.4);
  width: 25%;
  height: 100%;
  top: 0;
  transform: translateY(calc(-50% - 2px));
  transform: skew(-45deg);
  position: absolute;
  transition: right 0.5s ease-in;

  z-index: 100;
}
.them2:hover .figure-image::before {
  left: 150%;
}

.info {
  margin-top: 12px;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 12px 0;
  font-weight: 900;

  transition: 0.75s;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
}
.info::after {
  position: absolute;
  content: "";
  display: block;
  left: -25%;
  right: -10%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  background-color:#000;
  transform-origin: right;
  transform: skewX(-45deg) scale(0, 1);
  z-index: -1;
  transition: transform 0.75s;
}
.info p {
  margin: 0;
  transition: all 0.75s;
}

.category__box:hover .info::after {
  transform: skewX(-45deg) scale(1, 1);
  transform-origin: left;
  transition: transform 0.75s;
}
.category__box:hover .info p {
  color: #fff;
}

@media screen and (max-width: 1080px) {
  .category__box {
    /*  --width: 150px;*/
  }

  .category__box {
    margin-top: 9px;
    margin-bottom: 9px;
  }
}
@media screen and (max-width: 500px) {
  .info p {
    font-size: 12px;
  }
}
</style>
