<template>
  <section
    v-if=" productList && productList.length"
    class="main_container pt-3 mt-2 mt-sm-1 mb-sm-5 mb-4 home-products-Slder"
  >
  
    <div
      v-if="titleBox"
      class="title-box d-flex align-items-center justify-content-center justify-content-lg-between"
    >
      <!-- <h3 class="font-weight-bold fontsize-xheavy">
        {{ titleBox }}
      </h3> -->
      <h6 class="font-weight-bold text-color-444 ml-3 fontsize-large ">
        <span style="font-weight: 900; font-size: 27px">{{ titleBox }}</span>
        <span> کفش یونیک </span>
      </h6>
      <router-link
        :to="`/products?sort=${activeLink}`"
        class="text-dark font-weight-bold d-none d-lg-block more-link"
        >مشاهده بیشتر</router-link
      >
    </div>
    <div class="mt-4 mt-sm-5" :id="sliderId">
      <div class="swiper-container" >
        <div
          class="swiper-wrapper"
          v-if="
            
            !disabledSliders.includes('myProducts-slider-home')
          "
        >
          <div
            class="swiper-slide px-1 box-shaddow20"
            v-for="(product, index) in productList"
            :key="index"
          >
            <Product :product="product"></Product>
          </div>
        </div>
      </div>

      <div class="text-center font-weight-bold mt-4 d-block d-lg-none ">
          <router-link
            :to="`/products?sort=${activeTabLink}`"
            class="more-link fontsize-small px-3 py-2 mt-3 d-inline-block"
            >مشاهده بیشتر</router-link
          >
        </div>
    </div>
  </section>
</template>

<script>
  import Swiper from "swiper/swiper-bundle.min";
  import Product from "@/parts/Front/components/product/Product";


  export default {
    name: "homeProductSlider",
    components: {
      Product,
    },
    data() {
      return {
        slider: null,


      
      };
    },
    inject: ["mediaQueries"],
    props: {
      typeSlider: {
        type: String,
        default: "new_products",
      },
      titleBox: {
        type: String,
        default: null,
      },
      sliderId: String,
      activeLink: String,
    },
    mounted() {
    if (this.$store.getters["front/getHomeData"] != null) this.initProductHomeSlider(true);

    },
    methods: {
      initProductHomeSlider(kill = false) {
        this.initSwiper(this.sliderId, kill, () => {
            if (this.slider) {
          this.slider.destroy();
        }
        this.slider =new Swiper(`#${this.sliderId} .swiper-container`, {
            spaceBetween: 30,
            loop: false,
            speed: 500,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            breakpoints: {
            250: {
              slidesPerView: 1.3,
              spaceBetween: 5,
            },
            370: {
              slidesPerView: 1.5,
              spaceBetween: 5,

            },
            500: {
              slidesPerView: 2.5,
              spaceBetween: 10,
         

            },
            600: {
              slidesPerView: 2.8,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 3.2,
              spaceBetween: 30,
            },
            1000: {
              slidesPerView: 3.6,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1300: {
              slidesPerView: 4.5,
              spaceBetween: 40,
            },
            
            1600: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
          },
            // breakpoints: {
            //   250: {
            //     slidesPerView: 2,
            //     spaceBetween: 1,
            //   },
            //   500: {
            //     slidesPerView: 2,
            //     spaceBetween: 15,
            //   },
            //   768: {
            //     slidesPerView: 3,
            //     spaceBetween: 20,
            //   },
            //   992: {
            //     slidesPerView: 3,
            //     spaceBetween: 20,
            //   },
            //   1200: {
            //     slidesPerView: 4,
            //     spaceBetween: 20,
            //   },
            //   1500: {
            //     slidesPerView: 5,
            //     spaceBetween: 20,
            //   },
            // },
          });
        });
      },

    },
    computed: {

      productList() {
        if (this.$store.getters["front/getHomeData"]) {
      return this.$store.getters["front/getHomeData"][this.typeSlider];
    } else {
      return null;
    }
      },
    },
    watch:{
      productList(){
        this.initProductHomeSlider(true)
      }
    }
  };
</script>

<style scoped>
.home-products-Slder {
  margin-top: 60px !important;
}
.more-link {
  border: 1px solid rgb(31 31 31/1);
  display: inline-block;
  width: fit-content;
  color: #000;
  font-size: 15px;
  padding:  6px 18px;
  border-radius: 8px;
  transition: all .3s linear;
}
.more-link:hover{
    background-color: #000;
    color: #fff !important;
}
@media screen and (max-width: 500px) {
  .recommended-products-category a.fontsize-medium {
    font-size: 13px !important;
  }
  .title-box h6 span:last-child{
    font-size: 16px !important;
    font-weight: 900;
  }
  .title-box h6 span:first-child{
    font-size: 22px !important;
    font-weight: 900;
  }
  .title-box a {
    font-size: 13px;
  }
  .home-products-Slder {
    margin-top: 40px !important;
  }
}
</style>
